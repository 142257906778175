<template scoped>
  <div>
    <el-form-item
      :rules="rules.operator"
      label="Operator"
      prop="operator.type"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="type_change"
        v-model="logic.operator.type"
        :style="{ width: '100%' }"
        placeholder="Select the Operator"
      >
        <el-option
          v-for="item in supportOperator"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component
      :is="content"
      :logic="logic"
      :endpoint="endpoint"
      :productId="productId"
    ></component>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    endpoint: String,
    productId: String,
  },
  computed: {},
  data: function () {
    return {
      content: null,
      supportOperator: [
        {
          name: "Checksum",
          value: "Checksum",
        },
        {
          name: "Mathematic",
          value: "Mathematic",
        },
        {
          name: "String",
          value: "String",
        }
      ],
      rules: {
        operator: [{ validator: this.validator_operator, trigger: "blur" }],
      },
    };
  },
  methods: {
    validator_operator: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the operator should not be empty"));
      } else {
        callback();
      }
    },
    type_change: function (val) {
      switch (val) {
        case "Checksum":
          this.content = () => import("./Checksum/Panel");
          break;
        case "Mathematic":
          this.content = () => import("./Mathematic/Panel");
          break;
        case "String":
          this.content = () => import("./String/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
  },
  created: function () {
    if (this.logic.operator.type) {
      this.type_change(this.logic.operator.type);
    }
  },
};
</script>